@color-accent: #3B94F2;
@color-accent-light: #78beff;
@color-accent-light05: #daebff99;
@color-accent2: #2560ad;
@color-accent2-light: #2c71cb;
@color-success: #34bfa3;
@color-warning: yellow;
@color-warning-light: #fff3cd;
@color-danger: #d00;
@color-danger-light: #f8d7da;
@color-info: #478fd9;
@color-white: #fff;
@color-black: #000;
@color-highlight: #ffffe0;
@color-gray-100: #eeeeee;
@color-gray-300: #dee2e6;
@color-gray-400: #c2c7d0;
@color-gray-400-50: #c2c7d077;
@color-gray-500: #bbbbbb;
@color-gray-700: #999999;
@color-gray-750: #4b545c;
@color-gray-800: #343a40;
@color-gray-bs-border: rgb(206, 212, 218);

:root {
  --color-accent: @color-accent;
  --color-accent-light: @color-accent-light;
  --color-accent-light05: @color-accent-light05;
  --color-accent2: @color-accent2;
  --color-accent2-light: @color-accent2-light;
  --color-success: @color-success;
  --color-warning: @color-warning;
  --color-danger: @color-danger;
  --color-white: @color-white;
  --color-black: @color-black;
  --color-gray-100: @color-gray-100;
  --color-gray-300: @color-gray-300;
  --color-gray-400: @color-gray-400;
  --color-gray-400-50: @color-gray-400-50;
  --color-gray-500: @color-gray-500;
  --color-gray-700: @color-gray-700;
  --color-gray-750: @color-gray-750;
  --color-gray-800: @color-gray-800;
  --color-gray-bs-border: @color-gray-bs-border;
}

.success {
  color: @color-success;
  border-color: @color-success;
}

.warning {
  color: @color-warning;
  border-color: @color-warning;
}

.danger {
  color: @color-danger;
  border-color: @color-danger;
}

.text-info {
  color: @color-info !important;
}

@media (min-width: 576px) {
  .nav-user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-top: -8px;
  }
}

.nav-user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 2rem;
  margin-top: -4px;
  width: 2rem;
}

.form-group.required .col-form-label:after {
  color: @color-danger;
  content: "*";
  position: absolute;
  margin-left: 3px;
}

.fit-row {
  display: flex;
  flex-direction: row;
  gap: .5em;

  align-items: flex-start;

  .form-check {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.alert {
  &.alert-with-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
      flex: 1;
    }
  }
}

/* Sweet alert */
.swal2-title {
  display: inline-block !important;
}

body.swal2-height-auto {
  height: inherit !important;
}

.swal2-popup.swal2-toast {
  padding: 10px 12px !important;
  display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
  text-align: left !important;
  margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-close {
  float: right;
  color: @color-white;
  opacity: .4;

  &:hover {
    color: @color-gray-100;
  }
}

.swal2-popup.swal2-toast .swal2-title {
  margin-bottom: 4px !important;
}

.swal2-styled.swal2-confirm {
  background-color: @color-accent;

  &:focus {
    box-shadow: 0 0 0 3px rgba(59, 148, 242, 0.5);
  }
}

.card {
  .card-header {
    background-color: rgba(0, 0, 0, .03);

    .card-title {
      font-weight: bold;
      text-align: left;
      float: none;
      margin-bottom: 0.4em;
    }

    .card-subtitle {
      text-align: left;
      font-weight: 600;
    }
  }
}


div.wrapper,
div.main-sidebar {
  min-height: 100vh !important;
  max-height: 100vh !important;
  height: 100vh !important;
  overflow: hidden;
}

div.content-wrapper {
  min-height: 100vh !important;
  max-height: 100vh !important;
  height: 100vh !important;
  overflow: auto;
}

.content-header h1 {
  font-size: 1.8rem;
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: @color-accent;

  > i.fas, > i.far {
    margin-right: 8px;
    font-size: 0.75em;
  }
}

.table-responsive {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  table.table {
    * {
      font-size: 14px !important;
    }

    th {
      white-space: nowrap;
    }

    th,
    td {
      padding: 8px;
      vertical-align: middle;
    }

    tr {
      border-bottom: transparent;
    }

    thead {
      background-color: @color-accent;
      color: @color-white;

      tr {
        height: 3rem;

        th {
          border-bottom: none;
        }
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: @color-accent-light05;
      }
    }
  }
}

table-card-footer * {
  font-size: 14px;
}

.main-sidebar {
  display: flex;
  flex-direction: column;

  sidebar-logo {
    .brand-text {
      font-weight: 900;
      color: @color-accent;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    flex: 1;

    sidebar-menu {
      display: flex;
      flex-direction: column;
      flex: 1;

      nav {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        padding-bottom: 1em;

        > ul:nth-child(2) {
          border-top: 1px solid @color-gray-750;
        }
      }
    }
  }

  .sidebar-toggler {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 6px 2px 4px;
    color: @color-gray-400;

    &:hover {
      color: @color-gray-400;
    }
  }
}

@media (max-width: 991px) {
  .sidebar-collapse {
    .main-sidebar {
      overflow: visible !important;

      .sidebar-toggler {
        position: fixed;
        left: 0;
        right: auto;
        padding: 2px 10px 2px 8px;
        background: @color-gray-800;
      }
    }
  }
}

.modal-sidebar-large,
.modal-sidebar {
  left: 0;
  bottom: 0;
  height: 100%;
  margin: 0;

  .modal-content {
    border: none;
    border-radius: 0;
    height: 100%;
    width: 900px;
    max-width: 100vw;

    .modal-header {
      border-radius: 0;
      height: 53px;
      max-height: 53px;
    }

    .modal-body {
      display: flex;
      height: calc(100vh - 53px);
      max-height: calc(100vh - 53px);

      tabset.tab-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        .tab-content {
          overflow-y: auto;
          flex: 1 1 auto;
        }
      }
    }
  }
}

.modal-sidebar-large {
  .modal-content {
    width: 1300px;
  }
}

.modal-content {
  border-radius: 10px;

  .modal-header {
    background: @color-accent2;
    color: @color-white;
    padding: 0.5rem;
    justify-items: center;
    align-items: center;

    button.close {
      padding: 0;
      margin: 0;

      span {
        color: @color-white;
        margin-right: 4px;
      }
    }
  }
}

.modal-dialog.modal-lg .modal-body {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .modal-dialog.modal-lg {
    width: 90vw;
    max-width: 90vw;
    overflow-x: hidden;
    margin: 1.75rem auto;
  }
}

.color-box-container {
  span {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 1px solid @color-gray-800;
    margin-right: 4px;
  }
}

.calendar-styles {
  &.cs-1 {
    background: repeating-linear-gradient(45deg, #ffffcf99, #fdfdba99 30px) !important;;
  }

  &.cs-2 {
    background: repeating-linear-gradient(45deg, #BFE5FF99, #c9ebff99 30px) !important;;
  }

  &.cs-3 {
    background: repeating-linear-gradient(45deg, #ffb63555, #ffbe6c55 30px) !important;;
  }

  &.cs-4 {
    background: repeating-linear-gradient(45deg, #ECFCD399, #e9ff9f99 30px) !important;;
  }

  &.cs-5 {
    background: repeating-linear-gradient(45deg, #fcd3ff99, #fadbff99 30px) !important;;
  }

  &.cs-invalid-1 {
    background: repeating-linear-gradient(-45deg, #f3f3f3AA, #e9e9e9AA 30px) !important;;
  }

  &.cs-invalid-2 {
    background: repeating-linear-gradient(-45deg, #e9dadaAA, #c98888AA 30px) !important;;
  }

  &.cs-invalid-3 {
    background: repeating-linear-gradient(-45deg, #636363AA, #818181AA 30px) !important;;
  }

  &.cs-invalid-4 {
    background: repeating-linear-gradient(-45deg, #bbbbbbAA, #737373AA 30px) !important;;
  }

  &.cs-invalid-5 {
    background: repeating-linear-gradient(-45deg, #000629AA, #242d65AA 30px) !important;
  }
}

.btn.btn-default.active {
  background-color: @color-gray-500;
}

.btn.btn-link,
.page-link,
.nav-link {
  color: @color-accent;
}

ul.nav {
  .nav-link.nav-link-accent {
    color: @color-accent;

    &:hover {
      color: @color-accent-light !important;
    }
  }
}

.nav-link.warning {
  color: @color-warning !important;
  border-color: @color-warning !important;
}

.page-item.active .page-link,
.btn.btn-primary:not(:hover) {
  background-color: @color-accent2-light;
  border-color: @color-accent2-light;
}

.btn.btn-primary:hover {
  background-color: @color-accent2;
  border-color: @color-accent2;
}

.btn .overflow-text-inside-button {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  position: relative;
  top: 4px;
}

.form-check-input:checked {
  background-color: @color-accent;
  border-color: @color-accent;
}

.md-custom-event-resource-avatar-container {
  display: block;
  position: absolute;
  top: -0.75em;
  left: 0;
  z-index: 100000000;
  text-align: left;
  width: 100%;
  padding-left: 0.5em;

  > span {
    display: inline-block;
    border-radius: 100%;
    width: 1.6em;
    height: 1.6em;
    line-height: 1.6em;
    font-size: 0.7em;
    text-align: center;
    box-sizing: content-box;
    color: @color-black;
    background: white;
    border: 2px solid white;
  }
}

.fas.fa-check-to-slot {
  margin-left: -1px;
}

input.input-highlight {
  background-color: @color-highlight;
}

.validation-in-input-group {
  display: block;
  width: 100%;
}

.custom-control.custom-middle {
  margin: auto auto;
  display: flex;
  align-items: center;
  height: 2.4em;
}

typeahead-container.dropdown.open.dropdown-menu {
  max-height: 300px !important;
}

.dropdown-menu.padding-no-wrap {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5em;
  white-space: nowrap;
}

.dropdown-menu {
  .dropdown-item-selected {
    border-left: 2px solid @color-accent;
  }

  .dropdown-item.dropdown-item-hover-border {
    border: 2px dashed transparent;

    &:hover, &:focus {
      border: 2px dashed @color-gray-700;
      background-color: transparent;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

.flip-animation {
  animation: flip 1s cubic-bezier(0.93, 0.05, 0.9, 0.71) alternate;
  animation-iteration-count: 4;
}

@keyframes flip {
  0% {
    transform: scaleX(0.95);
  }

  100% {
    transform: scaleX(0.08);
  }
}

.form-check:not(.form-check-inline) .form-check-input {
  margin-left: -1.25em
}

.form-control.color-picker-control {
  cursor: pointer;
}

.form-control.number-input {
  width: 50px;
}

.form-control.form-control-text {
  border: none;
  padding-left: 0;
  margin-top: 1px;
}

.card-table-component {
  display: flex;
  flex-direction: column;
  height: 100vh;

  > .content-header {
    height: 70px;
    max-height: 70px;
  }

  > .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);

    > .container-fluid {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-bottom: 1rem;

      &:not(.container-fluid-endless) {
        max-height: 100%;
      }

      > .card {
        flex: 1;
        max-height: 100%;
        margin-bottom: 0;

        > .card-body {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.custom-control-label {
  padding-top: 1px;
}
